import { ref, computed } from 'vue'
import { PDFDocument, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import { format } from 'date-fns'
import { FORBIDDEN, UNAUTHORIZED } from '~/constants/http'
import { HttpClientProxy } from '~/utils/http'
import { getNewToken } from '~/utils/auth'
import fontUrl from '~/assets/fonts/Montserrat-Medium.ttf'
import { useNuxtApp, useRuntimeConfig } from '#app'
import { logError } from '~/utils/log-error'
import useAuthStore from '~/stores/auth'
import type { TGeneral } from '~/src/types/common'
import type { TReport } from '~/src/types/reports'

export function useDownloadReport() {
  const downloading = ref(false)
  const downloadProgress = ref(0)
  const retries = ref(0)
  let abortController: AbortController | null = null
  const { $cookies, $axios, $gtag, $membershipApi, $rudderstack }: TGeneral =
    useNuxtApp()
  const { authenticated, fullName, user } = useAuthStore()
  const progressBarDownloadingStyles = computed(() => ({
    width: `${downloadProgress.value > 5 ? downloadProgress.value : 0}%`,
  }))
  const apiRestEndpoint = useRuntimeConfig().public.apiRestEndpoint

  const resetDownloadState = () => {
    downloading.value = false
    downloadProgress.value = 0
    abortController = null
  }

  const cancelDownload = () => {
    if (!abortController) return

    abortController.abort()
    resetDownloadState()
  }

  const downloadReportRequest = async (
    url,
    filename = null,
    onErrorCallback = null,
    category = null,
    isMultiple = false,
  ) => {
    if (downloading.value) return

    downloading.value = !isMultiple

    const options = await HttpClientProxy.buildHttpOptions(
      {},
      $cookies,
      useRuntimeConfig(),
    )
    abortController = new AbortController()

    await $axios({
      method: 'POST',
      url,
      responseType: 'blob',
      headers: options.headers,
      signal: abortController.signal,
      onDownloadProgress: (progressEvent) => {
        const total = parseFloat(progressEvent.total.toString())
        const current = progressEvent.loaded
        downloadProgress.value = Math.floor((current / total) * 100)
      },
    })
      .then(async ({ data: blob, headers }) => {
        retries.value = 0
        const isPDF = extractFilenameFromHeaders(headers)?.endsWith('.pdf')
        let blobFile = blob
        if (isPDF && category !== 'public-documents') {
          const pdfWithWatermark = await addWatermarkInPDF(blob)
          blobFile = new Blob([pdfWithWatermark], {
            type: 'application/pdf',
          })
        }
        const blobUrl = window.URL.createObjectURL(blobFile)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = blobUrl
        a.download = filename || extractFilenameFromHeaders(headers)
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(blobUrl)
        document.body.removeChild(a)

        $gtag.event('ce_file_download', {
          event_category: 'download',
          value: filename,
          send_to: 'default',
        })
      })
      .catch(async (e) => {
        if (e.status === FORBIDDEN || e.response?.status === FORBIDDEN) {
          await $membershipApi.getActiveMemberships()

          if (onErrorCallback && typeof onErrorCallback === 'function') {
            onErrorCallback()
          }
        }

        if (e.response?.status === UNAUTHORIZED && retries.value < 1) {
          await getNewToken({
            $cookies: $cookies,
            $config: useRuntimeConfig(),
          })
          downloading.value = false
          retries.value++
          await downloadReportRequest(url, filename, onErrorCallback)
        }
      })
      .finally(() => {
        resetDownloadState()
      })
  }

  const downloadReport = async (
    report: TReport,
    category: string,
    onErrorCallback = null,
  ): Promise<void> => {
    if (!report.file_name) return
    const id = report.id || report.ID

    $rudderstack.trackDownloads({
      file_id: id,
      file_name: report.file_name,
      file_type: report.file_extension,
    })

    await downloadReportRequest(
      `${apiRestEndpoint}/downloads/category/${category}/${id}`,
      report.file_name,
      onErrorCallback,
      category,
    )
  }

  const downloadReportById = async (
    id,
    onErrorCallback = null,
  ): Promise<void> => {
    $rudderstack.trackDownloads({
      file_id: id,
      file_name: 'Dynamic report download by id',
    })

    await downloadReportRequest(
      `${apiRestEndpoint}/downloads/report/${id}`,
      null,
      onErrorCallback,
    )
  }

  const downloadReportByIds = async (ids, onErrorCallback = null) => {
    await Promise.all(
      ids.map(async (id) => {
        await downloadReportRequest(
          `${apiRestEndpoint}/downloads/report/${id}`,
          null,
          onErrorCallback,
          null,
          true,
        )
      }),
    )
  }

  const extractFilenameFromHeaders = (headers) => {
    const rawFilename = headers['content-disposition']
    return rawFilename.split('filename=')[1]
  }

  const addWatermarkInPDF = async (blob) => {
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const pdfBuffer = new Uint8Array(await newBlob.arrayBuffer())
    const pdfData = new Uint8Array(pdfBuffer)
    const pdfDoc = await PDFDocument.load(pdfData)
    pdfDoc.registerFontkit(fontkit)
    const font = await pdfDoc.embedFont(fontBytes)
    const pages = pdfDoc.getPages()
    const generateDate = format(new Date(), 'dd.MM.yyyy.')

    let message = 'Generated by Benchmark Mineral Intelligence'
    if (authenticated) {
      message = `Generated by Benchmark Mineral Intelligence for ${fullName} - ${user?.email} on ${generateDate} Not for further distribution.`
    }

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = '10px Montserrat-Medium'

    const pixelText = context.measureText(message).width + 27
    const { width: pageWidth, height } = pages[0].getSize()

    let arrayMessages = []
    if (pixelText > pageWidth) {
      arrayMessages = message.split('-').slice()
    } else {
      arrayMessages.push(message)
    }

    pages.forEach((page) => {
      arrayMessages.forEach((message, index) => {
        page.drawText(message, {
          x: 27,
          y: height - 10 * (index + 1),
          size: 8,
          font,
          color: rgb(0, 0, 0),
          opacity: 0.25,
        })
      })
    })

    return pdfDoc.save()
  }

  const getVimeoData = async (vimeoId, vimeoAuthToken) => {
    if (downloading.value) return
    downloading.value = true

    const response = await fetch(`https://api.vimeo.com/videos/${vimeoId}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${vimeoAuthToken}`,
        'Content-Type': 'application/json',
      },
    })
    const clonedResponse = response.clone()

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const contentLength = clonedResponse.headers.get('content-length')
    const total = parseInt(contentLength, 10)
    let loaded = 0
    const reader = clonedResponse.body.getReader()
    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        break
      }
      loaded += value.length
      downloadProgress.value = Math.floor((loaded / total) * 100)
    }
    return response?.json()
  }

  const downloadVideoVimeo = async (vimeoId, vimeoAuthToken) => {
    try {
      const data = await getVimeoData(vimeoId, vimeoAuthToken)
      const downloadUrl = data.download.find(
        (download) =>
          download.quality === 'hd' && download.rendition === '720p',
      )?.link
      if (downloadUrl) {
        $rudderstack.trackDownloads({
          file_id: vimeoId,
          file_name: data.name,
          file_type: 'mp4',
        })
        downloadVideo(downloadUrl)
      }
    } catch (_error) {
      logError(_error)
    } finally {
      resetDownloadState()
    }
  }

  const downloadVideo = (url) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return {
    downloading,
    downloadProgress,
    progressBarDownloadingStyles,
    downloadReport,
    downloadReportRequest,
    addWatermarkInPDF,
    getVimeoData,
    downloadVideoVimeo,
    downloadVideo,
    resetDownloadState,
    cancelDownload,
    downloadReportById,
    downloadReportByIds,
  }
}
